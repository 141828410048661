<template>
    <div>
        <h1>下载页。。。</h1>
        <a id="down_file"></a>

    </div>
</template>

<script>
// import { downFile } from '@/api/file'
import { axios } from '@/utils/request'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import Vue from 'vue'

export default {
    name: 'DownloadFile',
    data() {
        return {
            description: '下载页面'
        }
    },
    created() {
        const path = this.$route.query.path
        if (path === undefined) {
            this.$router.back(-1)
        }
        const data = {}
        data.path = path
        this.successDown('/admin/file/downFile', data)
        // this.loadLoadFile('/admin/file/downFile', data)
        // this.XHRLoadLoadFile('/admin/file/downFile', data)
    },
    methods: {
        // axios方式下载
        loadLoadFile(url, data) {
            const path = data.path
            const fileName = path.split('/')[path.split('/').length - 1]
            axios.post(url, data, {
                headers: {
                    // contentType: 'application/json;charset=UTF-8',
                    responseType: 'blob'
                }
            }).then(res => {
                const blob = new Blob([res])
                const url = window.URL.createObjectURL(blob)
                // 创建一个a标签元素，设置下载属性，点击下载，最后移除该元素
                const link = document.createElement('a')
                link.href = url
                link.style.display = 'none'
                // res.headers.fileName 取出后台返回下载的文件名
                // const downlaodFileName = decodeURIComponent(res.headers.filename)
                // const fileName = data.path.split('/')[data.path.split('/').length() - 1]
                link.setAttribute('download', fileName)
                link.click()
                window.URL.revokeObjectURL(url)
                this.$router.back(-1)
            }).catch(error => {
                console.log(error)
            })
        },
        // 第三种方法下载，可行
        successDown(url, data) {
            axios({
                method: 'post',
                url: url,
                responseType: 'blob',
                data: data
            }).then((res) => {
                // headers是否存在，存在为文件流，因为request.js中判断了content-disposition是否存在，返回response
                if (res.hasOwnProperty('headers')) {
                    const disposition = res.headers['content-disposition']
                    const r = /(?<=").*?(?=")/
                    const fileName = disposition.match(r)[0]
                    const downlaodFileName = decodeURIComponent(fileName)
                    var reader = new FileReader()
                    reader.readAsText(res.data)
                    reader.onload = (e) => {
                        this.downloadFile(downlaodFileName, res.data)
                        console.log('文件已经成功保存')
                        window.close()
                    }
                } else {
                    console.log(res)
                    // 发生错误，返回json
                    this.$notification.error({
                        message: '错误',
                        description: '文件未找到',
                        duration: 4
                    })
                }
            })
        },
        downloadFile(title, data) {
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(data)
            link.download = title
            link.click()
            window.URL.revokeObjectURL(link.href)
        },
        // xhr方式下载
        XHRLoadLoadFile(url, data) {
            const _this = this
            const xhr = new XMLHttpRequest()
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    const blob = new Blob([xhr.response])
                    const url = window.URL.createObjectURL(blob)
                    // 创建一个a标签元素，设置下载属性，点击下载，最后移除该元素
                    const link = document.createElement('a')
                    link.href = url
                    link.style.display = 'none'
                    // 取出下载文件名
                    const disposition = xhr.getResponseHeader('content-disposition')
                    if (disposition === null) {
                        _this.$notification.error({
                            message: '错误',
                            description: '文件未找到',
                            duration: 4
                        })
                    } else {
                        console.log(disposition)
                        const r = /(?<=").*?(?=")/
                        const fileName = disposition.match(r)[0]
                        const downlaodFileName = decodeURIComponent(fileName)
                        link.setAttribute('download', downlaodFileName)
                        link.click()
                        window.URL.revokeObjectURL(url)
                        window.location.href = 'about:blank'
                        window.close()
                    }
                }
            }
            // 将open()以及send()放到 xhr.onreadystatechange 之后生效
            xhr.open('post', '/api/' + url)
            xhr.setRequestHeader('Content-type', 'application/json;charset=UTF-8')
            xhr.setRequestHeader('authorization', Vue.ls.get(ACCESS_TOKEN))
            xhr.responseType = 'arraybuffer/blob'
            xhr.send(JSON.stringify(data))
        }
    }
}
</script>
